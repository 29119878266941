<template>
	<div class="page-onboarding">
		<h2 class="text-center my-0">Welcome to</h2>
		<p class="text-center">
			<img
				src="https://files.heygov.com/assets/heygov-logo.png"
				width="60"
				height="60"
				class="mb-4"
				alt="HeyGov"
			/>
		</p>

		<div class="row justify-content-center">
			<div v-if="section === 'text'" class="col-sm-10 col-lg-8">
				<p class="lead text-center">
					Below are some FAQ you may have before you begin Onboarding. Please read, then click the button at
					the bottom to begin.
				</p>

				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-10 col-lg-8">
								<p class="bg-light d-inline-block py-2 px-3 rounded-1">
									What is Onboarding again?
								</p>
							</div>
						</div>
						<div class="row justify-content-end">
							<div class="col-10 col-lg-8 text-end">
								<p class="bg-info-lighter d-inline-block py-2 px-3 rounded-1">
									This is where we set up HeyGov for your municipality!
								</p>
							</div>
						</div>

						<div class="row">
							<div class="col-10 col-lg-8">
								<p class="bg-light d-inline-block py-2 px-3 rounded-1">
									Is this going to take a long time?
								</p>
							</div>
						</div>
						<div class="row justify-content-end">
							<div class="col-10 col-lg-8 text-end">
								<p class="bg-info-lighter d-inline-block py-2 px-3 rounded-1">
									Nope! It should only take 30-45 minutes to finish, maybe faster than that!
								</p>
							</div>
						</div>

						<div class="row">
							<div class="col-10 col-lg-8">
								<p class="bg-light d-inline-block py-2 px-3 rounded-1">
									How many licenses or permits can I submit?
								</p>
							</div>
						</div>
						<div class="row justify-content-end">
							<div class="col-10 col-lg-8 text-end">
								<p class="bg-info-lighter d-inline-block py-2 px-3 rounded-1">
									As many as you want! We do the first 5 for free and then charge a $200 fee for each
									additional form that we build. You can build them on your own for no additional
									charge!
								</p>
							</div>
						</div>

						<p class="text-center">
							If you have other questions that were not covered here, please send them over at
							<a href="mailto:support@heygov.com" class="text-highlight">support@heygov.com</a>. For now,
							let’s start our onboarding journey together!
						</p>
						<p class="text-center">
							<button class="btn btn-primary px-5" @click="section = 'steps'">
								Continue
							</button>
						</p>
					</div>
				</div>
			</div>

			<div v-else class="col-sm-10 col-lg-8">
				<p>These are the steps that we will go through to have HeyGov up and running:</p>

				<div class="card bg-light my-3">
					<div class="card-body p-3">
						<h3 class="my-0">
							<font-awesome-icon :icon="['fas', 'user']" class="fa-fw" /> HeyGov account
							<small class="text-muted float-end">~2 minutes</small>
						</h3>
					</div>
				</div>

				<div class="card bg-light my-3">
					<div class="card-body p-3">
						<h3 class="my-0">
							<font-awesome-icon :icon="['far', 'credit-card']" class="fa-fw" /> Billing
							<small class="text-muted float-end">~5 minutes</small>
						</h3>
					</div>
				</div>

				<div class="card bg-light my-3">
					<div class="card-body p-3">
						<h3 class="my-0">
							<font-awesome-icon :icon="['fas', 'gavel']" class="fa-fw" /> Departments
							<small class="text-muted float-end">~10 minutes</small>
						</h3>
					</div>
				</div>

				<div v-if="j.features.includes('issues')" class="card bg-light my-3">
					<div class="card-body p-3">
						<h3 class="my-0">
							<font-awesome-icon :icon="['fas', 'chart-bar']" class="fa-fw" /> Hey311 categories
							<small class="text-muted float-end">~5 minutes</small>
						</h3>
					</div>
				</div>

				<div v-if="j.features.includes('forms')" class="card bg-light my-3">
					<div class="card-body p-3">
						<h3 class="my-0">
							<font-awesome-icon :icon="['fas', 'clipboard-list']" class="fa-fw" /> Licenses &amp; permits
							<small class="text-muted float-end">~10 minutes</small>
						</h3>
					</div>
				</div>

				<div class="card bg-light my-3">
					<div class="card-body p-3">
						<h3 class="my-0">
							<font-awesome-icon :icon="['fas', 'code']" class="fa-fw" /> Embed widget on your website
							<small class="text-muted float-end">~5 minutes</small>
						</h3>
					</div>
				</div>

				<p class="text-center">
					<router-link :to="`/${j.slug}/onboarding/${token}/municipality`" class="btn btn-primary px-5">
						Continue to HeyGov account</router-link
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Onboarding',
	data() {
		return {
			token: this.$route.params.token,
			section: 'text',
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j']),
	},
}
</script>
