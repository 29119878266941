<template>
	<div class="page-onboarding">
		<h1 class="text-center">You just got a HeyGov!</h1>
		<p class="lead text-center">
			Citizens of <strong>{{ j.name }}</strong> can now use the HeyGov app 🎉
		</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<p class="lead">Welcome aboard!</p>
						<p>
							Don’t forget, we are here for you every step of the way. You can always email us at
							<a href="mailto:support@heygov.com" class="text-highlight">support@heygov.com</a> if you
							need any assistance.
						</p>

						<h3>Now, I’m sure you are wondering what to do next.</h3>

						<p>Here are some recommendations on what you can do to speed up HeyGov usage:</p>
						<ul class="mb-4">
							<li>Remind department members to accept the HeyGov invite in their emails</li>
							<li>Make a news announcement about the app</li>
							<li>Share the app with your friends</li>
							<li>Post links to the app on your {{ j.type }}’s website and Facebook page</li>
						</ul>

						<p>
							Look out for an email from us with some digital assets that you can use for announcing your
							HeyGov on social media!
						</p>

						<div class="row align-items-center mb-5">
							<div class="col-4 text-center">
								<a href="https://apps.apple.com/us/app/heygov/id1560855064" target="_blank"
									><img
										src="https://assets.website-files.com/60c0bf119dbe7a93934b9782/6178229b6eef9e398ae80840_Asset%207%404x.png"
										alt="HeyGov iOS app"
										width="125"
								/></a>
							</div>
							<div class="col-4 text-center">
								<a
									href="https://play.google.com/store/apps/details?id=com.heygov.app&hl=en_US&gl=US"
									target="_blank"
									><img
										src="https://assets.website-files.com/60c0bf119dbe7a93934b9782/617822ad772bfcf55c80f65e_Asset%208%404x.png"
										alt="HeyGov Android app"
										width="125"
								/></a>
							</div>
							<div class="col-4 text-center">
								<a href="https://app.heygov.com/" target="_blank"
									><img
										src="https://assets.website-files.com/60c0bf119dbe7a93934b9782/6176f7865b77592251bc192b_Frame%20285.png"
										alt="HeyGov web app"
										width="125"
								/></a>
							</div>
						</div>

						<p class="text-center">
							<router-link :to="`/${j.slug}/dashboard`" class="btn btn-primary"
								>Continue to {{ j.type }} Dashboard</router-link
							>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'OnboardingFinish',
	data() {
		return {
			token: this.$route.params.token,
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j', 'forms']),
	},
	created() {
		this.$store.dispatch('getForms')
		this.$store.dispatch('loadDepartments')
	},
	mounted() {},
	methods: {},
}
</script>
