<template>
	<div class="page-onboarding">
		<h1 class="text-center">Permits &amp; Licenses</h1>
		<p class="lead text-center">What forms should appear in the app?</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<div v-if="forms.length" class="row row-cols row-cols-2 row-cols-lg-3 mb-3">
							<div
								class="col my-2"
								v-for="form in forms.filter(f => f.status !== 'archived')"
								:key="`form-${form.id}`"
							>
								<form-card :form="form" class="h-100"></form-card>
							</div>
						</div>
						<p v-else class="text-center">Start by creating the first permit or license</p>

						<p class="text-center mb-5">
							<button
								class="btn btn-success btn-big-icon"
								data-bs-toggle="modal"
								data-bs-target="#form-add"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" /> Create form
							</button>
						</p>

						<p class="">
							Add up to 5 forms for us to digitize for free. Each additional form you would like us to
							digitize will be $200. You can create as many forms as you like on your own for no
							additional charge. For any questions email
							<a href="mailto:support@heygov.com" class="text-highlight">support@heygov.com</a>
						</p>

						<div class="row align-items-center mt-4">
							<div class="col">
								<router-link
									:to="`/${j.slug}/onboarding/${token}/departments`"
									class="btn btn-sm btn-outline-primary"
									>Back to Departments</router-link
								>
							</div>
							<div class="col text-end">
								<router-link :to="`/${j.slug}/onboarding/${token}/widget`" class="btn btn-primary"
									>Continue →</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Add Form Modal -->
		<div class="modal fade" id="form-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="saveForm">
						<div class="modal-header">
							<h5 class="modal-title">Create Form</h5>

							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="name">Name <span class="text-danger">*</span></label>
								<input
									class="form-control"
									id="name"
									v-model="formNew.name"
									placeholder="License or Permit name"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="form-description">Description</label>
								<textarea
									class="form-control"
									id="form-description"
									v-model="formNew.description"
									rows="3"
									placeholder="License or Permit description"
								></textarea>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="form-department">Department</label>
								<select class="form-select" id="form-department" v-model="formNew.department_id">
									<option value="">Select a department</option>
									<option
										v-for="department in activeDepartments"
										:key="`form-dept-${department.id}`"
										:value="department.id"
										>{{ department.name }}</option
									>
								</select>
							</div>

							<div class="form-group">
								<label class="form-label" for="file">Form PDF</label>
								<input
									class="form-control"
									type="file"
									id="file"
									@change="handleFileInput($event)"
									accept="application/pdf"
								/>
								<small class="form-text">We'll digitize the form for you</small>
							</div>
						</div>
						<div class="modal-footer text-end">
							<button class="btn btn-primary">Create form</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import heyGovApi from '@/api.js'
import FormCard from '@/components/forms/FormCard.vue'
import { Modal } from 'bootstrap'

export default {
	name: 'OnboardingForms',
	components: {
		FormCard,
	},
	data() {
		return {
			token: this.$route.params.token,
			$modalForm: null,
			formNew: {
				id: null,
				slug: null,
				name: '',
				description: '',
				department_id: '',
				upload_file: null,
			},
		}
	},
	computed: {
		...mapGetters(['auth', 'activeDepartments']),
		...mapState(['j', 'forms']),
	},
	created() {
		this.$store.dispatch('getForms')
		this.$store.dispatch('loadDepartments')
	},
	mounted() {
		this.$modalForm = new Modal(document.getElementById('form-add'))
	},
	methods: {
		saveForm() {
			heyGovApi
				.post(`/${this.j.slug}/forms`, {
					name: this.formNew.name,
					description: this.formNew.description,
					department_id: this.formNew.department_id,
				})
				.then(
					({ data }) => {
						this.formNew.id = data.id
						this.formNew.slug = data.slug

						this.$store.commit('addForm', data)

						if (this.formNew.upload_file) {
							this.uploadFormFile()
						} else {
							this.formCreationFinished()
						}
					},
					error => {
						Vue.toasted.error(error)
					}
				)
		},
		handleFileInput($event) {
			this.formNew.upload_file = $event.target.files[0]
		},
		uploadFormFile() {
			// prepare file data
			var formFile = new FormData()
			formFile.append('file', this.formNew.upload_file)

			// upload the file
			heyGovApi.post(`/${this.j.slug}/forms/${this.formNew.id}/file`, formFile).then(
				() => {
					this.formCreationFinished()
				},
				error => {
					Vue.toasted.error(`Error uploading pdf ~ ${error.message}`)
				}
			)
		},
		formCreationFinished() {
			Vue.toasted.success('Form created!')
			this.formNew.name = ''
			this.formNew.description = ''
			this.formNew.upload_file = null

			this.$modalForm.hide()
		},
	},
}
</script>
