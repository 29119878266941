<template>
	<div class="page-onboarding">
		<h1 class="text-center">Create HeyGov account</h1>
		<p class="lead text-center">Let's start with creating your HeyGov account</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<form v-if="section === 'admin'" @submit.prevent="personAuth">
							<p class="lead">Information about you</p>

							<div class="form-group mb-3">
								<label class="form-label" for="person-name">Your full name</label>
								<input
									type="text"
									class="form-control"
									id="person-name"
									v-model="account.name"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="person-email">Your email</label>
								<input
									type="email"
									class="form-control"
									id="person-email"
									v-model="account.email"
									required
								/>
							</div>

							<p class="text-end mb-0">
								<button class="btn btn-primary">Continue</button>
							</p>
						</form>

						<form v-else-if="section === 'muni'" @submit.prevent="saveMuni">
							<p class="lead">Does the {{ j.type }} information looks correct?</p>

							<div class="form-group mb-3">
								<label class="form-label" for="muni-name">{{ j.type }} name</label>
								<input
									type="text"
									class="form-control"
									id="muni-name"
									v-model="j.name"
									required
									placeholder="Municipality name"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="muni-website">Website</label>
								<input
									type="url"
									class="form-control"
									id="muni-website"
									v-model="j.website"
									required
									placeholder="Municipality website"
								/>
							</div>

							<div class="row">
								<div class="col"></div>
								<div class="col text-end">
									<button class="btn btn-primary">Continue</button>
								</div>
							</div>
						</form>

						<form v-else-if="section === 'location'" @submit.prevent="saveMuniLocation">
							<p class="lead">Is there where your {{ j.type }} is located?</p>
							<p>Please select a location, then center the map &amp; zoom</p>

							<div class="form-group mb-3">
								<label class="form-label" for="muni-location">Location</label>
								<gmap-autocomplete
									@place_changed="setLocation"
									:select-first-on-enter="true"
									:options="autocompleteOptions"
								>
									<template v-slot:default="slotProps">
										<input
											id="muni-location"
											class="form-control form-control-sm"
											ref="input"
											:value="j.location.name"
											placeholder="Municipality location"
											required
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<muni-map class="mb-3" :height="350" @zoom_changed="updateLocationZoom"></muni-map>

							<div class="row">
								<div class="col">
									<button class="btn btn-outline-info" type="button" @click="section = 'muni'">
										Back
									</button>
								</div>
								<div class="col text-end">
									<button class="btn btn-primary">Continue</button>
								</div>
							</div>
						</form>

						<div v-else-if="section === 'done'">
							<p class="text-center">
								<img
									src="https://files.heygov.com/assets/illustration-band.png"
									alt="HeyGov"
									class="mb-0"
								/>
							</p>
							<h3 class="text-center my-5">Your account &amp; {{ j.type }} are now set up in HeyGov!</h3>

							<div class="row">
								<div class="col">
									<button class="btn btn-outline-info" type="button" @click="section = 'location'">
										Back
									</button>
								</div>
								<div class="col text-end">
									<router-link
										v-if="j.pricing !== 'townweb'"
										:to="`/${j.slug}/onboarding/${token}/billing`"
										class="btn btn-primary"
										>Onwards to Billing →</router-link
									>
									<router-link
										v-else
										:to="`/${j.slug}/onboarding/${token}/departments`"
										class="btn btn-primary"
										>Onwards to Departments →</router-link
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'

import MuniMap from '@/components/MuniMap.vue'

export default {
	name: 'Onboarding',
	components: { MuniMap },
	data() {
		return {
			token: this.$route.params.token,
			section: 'admin',
			account: {
				name: '',
				email: '',
			},
			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['address_components', 'formatted_address', 'geometry', 'place_id', 'website'],
				types: ['(cities)'],
			},
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j']),
	},
	created() {
		if (this.auth) {
			this.section = 'muni'

			// make current person ADMIN in this jurisdiction
			heyGovApi
				.post(`/${this.j.slug}/onboarding/person-auth`, {
					email: this.auth.email,
					name: this.auth.name,
					token: this.token,
				})
				.then(({ data }) => {
					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit(
						'setCurrentJurisdiction',
						data.jurisdictions.find(j => j.slug === this.j.slug)
					)
				}, Vue.toasted.error)
		}
	},
	methods: {
		personAuth() {
			heyGovApi
				.post(`/${this.j.slug}/onboarding/person-auth`, { ...this.account, token: this.token })
				.then(({ data }) => {
					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit(
						'setCurrentJurisdiction',
						data.jurisdictions.find(j => j.slug === this.j.slug)
					)
					this.$store.commit('setAuth', data.token)
					this.$store.dispatch('accountInfo')

					this.section = 'muni'
				}, Vue.toasted.error)
		},
		saveMuni() {
			this.$store.dispatch('saveCurrentJurisdiction').then(
				() => {
					this.section = 'location'
				},
				() => {
					Vue.toasted.error('save muni error')
				}
			)
		},
		setLocation(place) {
			let j = {
				...this.j,
				location: {
					place_id: place.place_id,
					name: place.formatted_address,
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng(),
					zoom: 10,
				},
			}

			this.$store.commit('updateCurrentJurisdiction', j)
		},
		updateLocationZoom(zoom) {
			this.$store.commit('updateCurrentJurisdiction', {
				location: { ...this.j.location, zoom },
			})
		},
		saveMuniLocation() {
			this.$store.dispatch('saveCurrentJurisdiction').then(
				() => {
					this.section = 'done'
				},
				() => {
					Vue.toasted.error('save muni error')
				}
			)
		},
	},
}
</script>
