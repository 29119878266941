<template>
	<div class="page-onboarding">
		<h1 class="text-center">Billing</h1>
		<p class="lead text-center">How do you want to pay for HeyGov?</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<form v-if="section === 'payment'" id="payment-form">
							<p>Based on the size of your {{ j.type }}, this is your personalized pricing:</p>

							<div v-if="j.stripe_invoice_id" class="mb-4">
								<div v-if="invoice">
									<table class="table">
										<thead>
											<tr>
												<th>Item</th>
												<th>Quantity</th>
												<th>Price</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="line in invoice.lines.data" :key="`inv-line-${line.id}`">
												<td>{{ line.description }}</td>
												<td>{{ line.quantity }}</td>
												<td>
													<strong>{{
														(line.price.unit_amount / 100).toLocaleString('en-US', {
															style: 'currency',
															currency: line.price.currency,
														})
													}}</strong>
												</td>
											</tr>
											<tr
												v-for="discount in invoice.total_discount_amounts"
												:key="`inv-disc-${discount.discount}`"
											>
												<td>
													<span
														v-if="invoice.discounts.find(d => d.id === discount.discount)"
														class="badge bg-info-lighter text-info"
														>{{
															invoice.discounts.find(d => d.id === discount.discount)
																.coupon.name
														}}</span
													>
													<span v-else class="badge bg-light text-gray">Discount</span>
												</td>
												<td></td>
												<td>
													<strong
														>-{{
															(discount.amount / 100).toLocaleString('en-US', {
																style: 'currency',
																currency: invoice.currency,
															})
														}}</strong
													>
												</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<th>Total</th>
												<td></td>
												<th>
													{{
														(invoice.total / 100).toLocaleString('en-US', {
															style: 'currency',
															currency: invoice.currency,
														})
													}}
												</th>
											</tr>
										</tfoot>
									</table>
								</div>
								<p v-else class="text-center">
									<span role="status" class="spinner-border spinner-border-sm text-primary"></span>
								</p>
							</div>
							<table v-else class="table">
								<thead>
									<tr>
										<th>Item</th>
										<th>Quantity</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>HeyGov Setup</td>
										<td>1</td>
										<th>
											<strong>$1,000</strong>
										</th>
									</tr>
									<tr>
										<td>Form Digitization</td>
										<td>1</td>
										<th>
											<strong>$1,000</strong>
										</th>
									</tr>
									<tr>
										<td>Point of Sale Terminal (POS)</td>
										<td>1</td>
										<th>
											<strong>$300</strong>
										</th>
									</tr>
									<tr>
										<td>HeyGov Service &amp; Support</td>
										<td>12 months</td>
										<th>
											<strong>${{ (pricing * 12).toLocaleString() }}/year</strong>
										</th>
									</tr>
									<tr v-if="foundingMember">
										<td>
											<span class="badge bg-success-lighter text-success"
												>Founding Member discount</span
											>
										</td>
										<td></td>
										<th>
											<strong>-$2,300</strong>
										</th>
									</tr>
								</tbody>
							</table>

							<div class="row align-items-center">
								<div class="col">
									<button
										class="btn btn-sm btn-outline-info"
										type="button"
										@click="section = 'later'"
									>
										Skip online payment
									</button>
								</div>
								<div class="col text-end">
									<a
										v-if="invoice"
										:href="invoice.hosted_invoice_url"
										class="btn btn-primary"
										@click="recheckInvoice"
										target="_blank"
										>Continue to Payment</a
									>
									<a
										v-else
										:href="checkoutSession ? checkoutSession.url : ''"
										class="btn btn-primary"
										:class="{ disabled: !checkoutSession }"
										>Continue to Payment</a
									>
								</div>
							</div>
						</form>

						<div v-else-if="section === 'complete'">
							<div class="bg-success-lighter rounded-2 p-3 text-center my-4">
								<h4 class="mt-3">Payment complete ✅</h4>
								<p v-if="j.stripe_invoice_id && invoice">
									Paid
									{{
										(invoice.total / 100).toLocaleString('en-US', {
											style: 'currency',
											currency: invoice.currency,
										})
									}}
								</p>
								<p v-else-if="j.stripe_subscription_id">
									HeyGov plan: ${{ (pricing * 12).toLocaleString() }} yearly
								</p>
								<p v-else class="text-center">
									<span role="status" class="spinner-border spinner-border-sm text-primary"></span>
								</p>
							</div>

							<div class="row">
								<div class="col">
									<router-link
										:to="`/${j.slug}/onboarding/${token}/municipality`"
										class="btn btn-sm btn-outline-primary"
										>Back to Account</router-link
									>
								</div>
								<div class="col text-end">
									<router-link
										:to="`/${j.slug}/onboarding/${token}/departments`"
										class="btn btn-primary"
										>Onwards to Departments →</router-link
									>
								</div>
							</div>
						</div>

						<div v-else-if="section === 'later'">
							<h5 class="text-center my-5">
								Contact us at <span class="text-highlight">support@heygov.com</span> to let us know how
								you are paying! 😀
							</h5>

							<div class="row">
								<div class="col">
									<button class="btn btn-outline-info" type="button" @click="section = 'payment'">
										Back
									</button>
								</div>
								<div class="col text-end">
									<router-link
										:to="`/${j.slug}/onboarding/${token}/departments`"
										class="btn btn-primary"
										>Onwards to Departments →</router-link
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import heyGovApi from '@/api.js'

export default {
	name: 'OnboardingBilling',
	data() {
		return {
			states: {
				stripe: 'loading',
			},
			token: this.$route.params.token,
			section: this.$route.query.section || 'payment',
			billingInterval: 'monthly',
			paymentMethod: 'card',
			stripe: null,
			checkoutSession: null,
			subscription: null,
			invoice: null,
			recheckTimer: null,
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j', 'stripePublishableKey']),
		pricing() {
			let price = 99

			if (this.j.pricing && this.j.pricing.includes('+')) {
				const parts = this.j.pricing.split('+')
				price = parts[0]
			} else if (this.j.pricing) {
				price = this.j.pricing
			}

			return parseInt(price, 10)
		},
		foundingMember() {
			let founding = false

			if (!this.j.pricing || (this.j.pricing && this.j.pricing.endsWith('+founding'))) {
				founding = true
			}

			return founding
		},
	},
	created() {
		if (this.j.stripe_subscription_id) {
			this.section = 'complete'
			this.stripeSubscription()
		} else if (this.$route.query.session_id) {
			this.stripeSession()
		} else if (this.j.stripe_invoice_id) {
			this.stripeInvoice()
		} else {
			this.stripeCheckout()
		}
	},
	methods: {
		stripeCheckout() {
			heyGovApi.get(`/${this.j.slug}/billing/stripe-checkout`).then(
				({ data }) => {
					this.checkoutSession = data
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		stripeSession() {
			heyGovApi.get(`/${this.j.slug}/billing/session/${this.$route.query.session_id}`).then(
				({ data }) => {
					this.j.stripe_subscription_id = data.id
					this.subscription = data
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		stripeSubscription() {
			heyGovApi.get(`/${this.j.slug}/billing/subscription/${this.j.stripe_subscription_id}`).then(
				({ data }) => {
					this.subscription = data
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		stripeInvoice() {
			console.log('stripeInvoice')
			heyGovApi.get(`/${this.j.slug}/billing/invoice/${this.j.stripe_invoice_id}`).then(
				({ data }) => {
					this.invoice = data

					if (this.invoice.status === 'paid') {
						clearInterval(this.recheckTimer)
						this.section = 'complete'
					}
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		recheckInvoice() {
			this.recheckTimer = setInterval(() => {
				this.stripeInvoice()
			}, 3000)
		},
	},
}
</script>
