<template>
	<div class="page-onboarding pt-2">
		<p class="text-center">
			<img
				src="https://edge.heygov.com/app-assets/logo-clerkminutes.svg"
				width="300"
				class="mb-3"
				alt="ClerkMinutes"
			/>
		</p>
		<p class="lead text-center mb-4">
			Register for a 14-day free trial of ClerkMinutes—no credit card required.
		</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card">
					<div class="card-body">
						<h3 v-if="auth">
							✅ Your account
							<small class="text-neutral-500"
								>- logged in as {{ auth.first_name || auth.name }} ({{ auth.email }})</small
							>
						</h3>
						<form v-else @submit.prevent="accountRegister">
							<h3 class="mb-4">🔵 Your account</h3>

							<div class="row">
								<div class="col-6 form-group mb-3">
									<label class="form-label" for="account-first-name">First name</label>
									<input
										type="text"
										id="account-first-name"
										class="form-control"
										required
										autocomplete="given-name"
										v-model="account.first_name"
										placeholder="Your first name"
									/>
								</div>

								<div class="col-6 form-group mb-3">
									<label class="form-label" for="account-last-name">Last name</label>
									<input
										type="text"
										id="account-last-name"
										class="form-control"
										required
										autocomplete="family-name"
										v-model="account.last_name"
										placeholder="Your last name"
									/>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="account-email">Your email</label>
								<input
									type="email"
									class="form-control"
									id="account-email"
									required
									autocomplete="email"
									v-model="account.email"
									placeholder="A valid email please"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="account-password">Password</label>
								<input
									type="password"
									class="form-control"
									id="account-password"
									required
									minlength="8"
									autocomplete="new-password"
									v-model="account.password"
									placeholder="Secure password with at least 8 characters"
								/>
							</div>

							<div
								v-if="states.account === 'error' && error"
								class="alert alert-danger"
								v-html="error.replace(/\n/g, '<br>')"
							></div>

							<p class="card-text text-center mt-4">
								<button class="btn btn-primary px-5" :disabled="states.account === 'loading'">
									Create account
								</button>
							</p>
						</form>

						<hr class="bg-primary-100 my-4" />

						<h3 v-if="!auth" class="my-0">⚪️ Municipality / Organization details</h3>
						<form v-if="auth" @submit.prevent="saveJurisdiction">
							<h3 class="mb-4">🔵 Municipality / Organization details</h3>

							<div class="row">
								<div class="col-3 form-group mb-3">
									<label class="form-label" for="jurisdiction-type"
										>Type <small class="text-danger-400">*</small></label
									>
									<select
										class="form-select form-select-sm"
										id="jurisdiction-type"
										required
										v-model="jurisdiction.type"
									>
										<option value="association">Association</option>
										<option value="borough">Borough</option>
										<option value="city">City</option>
										<option value="county">County</option>
										<option value="district">District</option>
										<option value="town">Town</option>
										<option value="township">Township</option>
										<option value="village">Village</option>
										<option value="personal">Personal / Freelancer</option>
										<option value="organization">Other organization</option>
									</select>
								</div>

								<div class="col-9 form-group mb-3">
									<label class="form-label" for="jurisdiction-name"
										>{{ jurisdiction.type === 'personal' ? 'Your' : jurisdiction.type }} name
										<small class="text-danger-400">*</small></label
									>
									<input
										type="text"
										class="form-control form-control-sm"
										id="jurisdiction-name"
										required
										v-model="jurisdiction.name"
										placeholder="Ex: Town of Springfield, City of Riverdale"
									/>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="jurisdiction-location"
									>Location <small class="text-danger-400">*</small></label
								>
								<gmap-autocomplete
									:select-first-on-enter="true"
									:options="{ types: ['(cities)'] }"
									@place_changed="setLocation"
								>
									<template v-slot:default="slotProps">
										<input
											ref="input"
											id="jurisdiction-location"
											placeholder="Municipality location, search and select one"
											class="form-control form-control-sm"
											v-on="slotProps.listeners"
											required
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<div class="row">
								<div class="col-6 form-group mb-3">
									<label class="form-label" for="town-website"
										>Website <small class="text-danger-400">*</small></label
									>
									<input
										type="text"
										class="form-control form-control-sm"
										id="town-website"
										required
										v-model="jurisdiction.website"
										:placeholder="`ex: https://${jurisdiction.type}.com`"
									/>
								</div>

								<div v-if="jurisdiction.type !== 'personal'" class="col-6 form-group mb-3">
									<label class="form-label" for="town-email">{{ jurisdiction.type }} email</label>
									<input
										type="email"
										class="form-control form-control-sm"
										id="town-email"
										v-model="jurisdiction.email"
										:placeholder="`Mailbox for your ${jurisdiction.type}`"
									/>
								</div>
							</div>

							<div v-if="states.referralCode" class="row">
								<div class="col-6 form-group mb-3">
									<label class="form-label" for="referral-code">Referral code</label>
									<input
										type="text"
										class="form-control form-control-sm"
										id="referral-code"
										v-model="referralCode"
										minlength="4"
									/>
								</div>
							</div>
							<p v-else class="text-neutral-400">
								<span class="text-primary-300 cursor-pointer" @click="states.referralCode = true"
									>Add a referral code</span
								>
								to extend the free trial to 30 days
							</p>

							<div v-if="jurisdictionError" class="alert alert-danger">
								⚠️ <strong>Error creating HeyGov account</strong>: {{ jurisdictionError }}
							</div>

							<p class="card-text text-center">
								<button class="btn btn-primary px-5" :disabled="states.jurisdiction === 'loading'">
									{{ states.jurisdiction === 'loading' ? 'Creating account..' : 'Continue' }}
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { parse } from 'tldts'

import heyGovApi from '@/api.js'
import { googleMapsApi } from '@/lib/geo.js'

export default {
	name: 'OnboardingHey311',
	data() {
		return {
			states: {
				account: 'idle',
				jurisdiction: 'idle',
				referralCode: false,
			},
			error: '',
			section: 'account',
			account: {
				first_name: '',
				last_name: '',
				email: '',
				password: '',
			},
			jurisdiction: {
				name: '',
				slug: '',
				type: 'city',
				email: '',
				website: '',
				location: {
					place_id: '',
					lat: 0,
					lng: 0,
					zoom: 12,
					city: '',
					county: '',
					state: '',
					country: 'US',
				},
				timezone: '',
				features: ['clerkminutes'],
				features_options: {},
				stripe_customer_id: 'new',
			},
			jurisdictionError: '',
			referralCode: '',
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j']),
	},
	created() {
		if (this.auth) {
			this.section = 'jurisdiction'
		}
	},
	methods: {
		accountRegister() {
			this.states.account = 'loading'

			heyGovApi.post('/account', this.account).then(
				({ data }) => {
					this.$store.commit('setAuth', data.token)
					this.$store.dispatch('accountInfo')

					this.states.account = 'idle'
					this.section = 'jurisdiction'
				},
				error => {
					this.error = error.response?.data?.detail || 'Server error, please try again later.'
					this.states.account = 'error'
				}
			)
		},
		setLocation(place) {
			this.jurisdiction.location.place_id = place.place_id
			this.jurisdiction.location.lat = place.geometry.location.lat()
			this.jurisdiction.location.lng = place.geometry.location.lng()
			this.jurisdiction.website ||= place.website

			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					this.jurisdiction.location.state = component.short_name
				} else if (component.types.includes('administrative_area_level_2')) {
					this.jurisdiction.location.county = component.short_name || component.long_name
				} else if (component.types.includes('country')) {
					this.jurisdiction.location.country = component.short_name
				} else if (
					component.types.includes('locality') ||
					(!this.jurisdiction.location.city && component.types.includes('administrative_area_level_3'))
				) {
					this.jurisdiction.location.city = component.short_name || component.long_name
				}
			})

			// get timezone for this location
			googleMapsApi(
				`timezone/json?location=${place.geometry.location.lat()},${place.geometry.location.lng()}&timestamp=${Math.floor(
					Date.now() / 1000
				)}`
			)
				.then(data => {
					if (data.status === 'OK' && data.timeZoneId) {
						this.jurisdiction.timezone = data.timeZoneId
					} else {
						console.log('timezone response', data)
						throw new Error('Error finding timezone for this location. See dev console for more info')
					}
				})
				.catch(error => {
					alert(error.message)
				})
		},
		saveJurisdiction() {
			this.jurisdictionError = ''
			const keywordBlacklist = [
				'no',
				'test',
				'testtest',
				'example',
				'justtesting',
				'heygov',
				'clerkminutes',
				'gmail',
				'google',
				'zoom',
				'yahoo',
			]

			const domain = parse(this.jurisdiction.website)

			if (domain && domain.isIcann && !keywordBlacklist.includes(domain.domainWithoutSuffix)) {
				this.jurisdiction.slug = domain.hostname.replace('www.', '')

				if (!this.jurisdiction.website.startsWith('http')) {
					this.jurisdiction.website = `https://${this.jurisdiction.website}`
				}
			} else {
				alert('Invalid website URL')
				return
			}

			if (!this.jurisdiction.email) {
				this.jurisdiction.email = this.auth.email
			}

			this.states.jurisdiction = 'loading'

			heyGovApi.post('/jurisdictions?source=cm&selfadmin=1', this.jurisdiction).then(
				({ data }) => {
					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit('setCurrentJurisdiction', data.jurisdictions.at(-1))

					heyGovApi
						.post(`/${this.j.slug}/meetings/start-clerkminutes-subscription`, {
							referral_code: this.referralCode,
						})
						.then(() => {
							this.$router.push(`/${this.j.slug}/meetings?unlocked=1`)
						})
						.catch(error => {
							Vue.toasted.error(`Error starting ClerkMinutes (${error.message})`)
							this.$router.push(
								`/${this.j.slug}/meetings/clerkminutes?referral_code=${this.referralCode}`
							)
						})
				},
				error => {
					this.jurisdictionError = error.response?.data?.message || 'Server error, please try again later.'
					this.states.jurisdiction = 'error'
				}
			)
		},
	},
}
</script>
