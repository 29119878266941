<template>
	<div class="page-onboarding pt-4">
		<p class="text-center">
			<img
				src="https://files.heygov.com/assets/heygov-logo.png"
				width="60"
				height="60"
				class="mb-4"
				alt="HeyGov"
			/>
		</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<p v-if="!j" class="lead text-center">Register to get Hey311 for your municipality.</p>

				<div v-if="section === 'account'" class="card">
					<div class="card-body">
						<form @submit.prevent="accountRegister">
							<div class="form-group mb-3">
								<label class="form-label">Your name</label>
								<input type="text" class="form-control" required v-model="account.name" />
							</div>

							<div class="form-group mb-3">
								<label class="form-label">Your email</label>
								<input
									type="email"
									class="form-control"
									required
									v-model="account.email"
									placeholder="A valid email please"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label">Password</label>
								<input
									type="password"
									class="form-control"
									required
									v-model="account.password"
									placeholder="Secure password with at least 6 characters"
								/>
							</div>

							<div v-if="states.account === 'error' && error" class="alert alert-danger">
								{{ error }}
							</div>

							<p class="card-text text-center mt-4">
								<button class="btn btn-primary px-5" :disabled="states.account === 'loading'">
									Create account
								</button>
							</p>
						</form>
					</div>
				</div>

				<div v-else-if="section === 'jurisdiction'" class="card">
					<div class="card-body">
						<h3 class="mb-3">Municipality details</h3>

						<form @submit.prevent="saveJurisdiction">
							<div class="form-group mb-3">
								<label class="form-label" for="town-type">Municipality type</label>
								<select
									class="form-select form-select-sm"
									id="town-type"
									required
									v-model="jurisdiction.type"
								>
									<option value="town">Town</option>
									<option value="city">City</option>
									<option value="village">Village</option>
								</select>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-name"
									>{{ jurisdiction.type || 'municipality' }} name</label
								>
								<input
									type="text"
									class="form-control form-control-sm"
									id="town-name"
									required
									v-model="jurisdiction.name"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-location">Location</label>
								<gmap-autocomplete
									class="form-control form-control-sm"
									id="town-location"
									required
									placeholder="Municipality location"
									@place_changed="setLocation"
									:select-first-on-enter="true"
									:options="{ types: ['(cities)'] }"
								>
								</gmap-autocomplete>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-website">Website</label>
								<input
									type="url"
									class="form-control form-control-sm"
									id="town-website"
									required
									v-model="jurisdiction.website"
									:placeholder="`ex: https://${jurisdiction.type}.com`"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-email"
									>{{ jurisdiction.type || 'municipality' }} email</label
								>
								<input
									type="email"
									class="form-control form-control-sm"
									id="town-email"
									required
									v-model="jurisdiction.email"
								/>
								<small class="form-text">Contact email for your {{ jurisdiction.type }}</small>
							</div>

							<p class="card-text text-center">
								<button class="btn btn-primary px-5">Continue</button>
							</p>
						</form>
					</div>
				</div>

				<div v-if="section === 'onboarding' && j" class="">
					<p class="lead">👏 Your {{ j.type }} is registered for HeyGov now!</p>

					<p>These are the next steps that we will go through to have HeyGov up and running:</p>

					<div class="card bg-light my-3">
						<div class="card-body p-3">
							<h3 class="my-0">
								<font-awesome-icon :icon="['fas', 'gavel']" class="fa-fw" />
								<span class="text-capitalize">{{ j.type }}</span> departments
								<small class="text-muted float-end">~10 minutes</small>
							</h3>
						</div>
					</div>

					<div class="card bg-light my-3">
						<div class="card-body p-3">
							<h3 class="my-0">
								<font-awesome-icon :icon="['fas', 'chart-bar']" class="fa-fw" /> Hey311 categories &amp;
								settings
								<small class="text-muted float-end">~5 minutes</small>
							</h3>
						</div>
					</div>

					<div class="card bg-light my-3">
						<div class="card-body p-3">
							<h3 class="my-0">
								<font-awesome-icon :icon="['fas', 'code']" class="fa-fw" /> Embed widget on your website
								<small class="text-muted float-end">~5 minutes</small>
							</h3>
						</div>
					</div>

					<p class="text-center">
						<router-link
							:to="`/${jurisdiction.slug}/onboarding/${jurisdiction.uuid}/departments`"
							class="btn btn-primary px-5"
						>
							Continue
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import heyGovApi from '@/api.js'

export default {
	name: 'OnboardingHey311',
	data() {
		return {
			states: {
				account: 'idle',
				jurisdiction: 'idle',
			},
			error: '',
			section: 'account',
			account: {
				name: '',
				email: '',
				password: '',
			},
			jurisdiction: {
				name: '',
				slug: '',
				uuid: '',
				type: 'town',
				email: '',
				website: '',
				location: {
					name: '',
					lat: 0,
					lng: 0,
					zoom: 10,
				},
				timezone: '',
				features: ['issues'],
				features_options: {
					issues_privacy: 'public',
				},
			},
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j']),
	},
	created() {
		if (this.auth) {
			this.section = 'jurisdiction'
		}
	},
	methods: {
		accountRegister() {
			this.states.account = 'loading'

			heyGovApi.post('/account', this.account).then(
				({ data }) => {
					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit('setAuth', data.token)
					this.$store.dispatch('accountInfo')

					this.states.account = 'idle'
					this.section = 'jurisdiction'
				},
				error => {
					this.error = error?.response?.data?.detail || 'Something went wrong'
					this.states.account = 'error'
				}
			)
		},
		async setLocation(place) {
			this.jurisdiction.location.name = place.formatted_address
			this.jurisdiction.location.lat = place.geometry.location.lat()
			this.jurisdiction.location.lng = place.geometry.location.lng()
			this.jurisdiction.website ||= place.website

			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					this.jurisdiction.location.state = component.short_name
				} else if (component.types.includes('country')) {
					this.jurisdiction.location.country = component.short_name
				}
			})

			// get timezone for this location
			const reTimezone = await axios.get(
				`https://maps.googleapis.com/maps/api/timezone/json?location=${place.geometry.location.lat()},${place.geometry.location.lng()}&timestamp=${Math.floor(
					Date.now() / 1000
				)}&key=AIzaSyDZaCFmKFunFe-HG-uY7LGwXpNK2pV_7A4`
			)
			this.jurisdiction.timezone = reTimezone.data.timeZoneId
		},
		saveJurisdiction() {
			this.states.jurisdiction = 'loading'

			heyGovApi.post('/jurisdictions', this.jurisdiction).then(
				({ data }) => {
					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit('setCurrentJurisdiction', data.jurisdictions[0])
					this.$store.dispatch('loadJurisdictions')

					this.jurisdiction.uuid = data.jurisdiction.uuid

					this.states.jurisdiction = 'idle'
					this.section = 'onboarding'
				},
				error => {
					Vue.toasted.error(error)
					this.states.jurisdiction = 'error'
				}
			)
		},
		updateSlug() {
			try {
				const url = new URL(this.jurisdiction.website)

				if (url && url.hostname && !this.jurisdiction.slug) {
					this.jurisdiction.slug = url.hostname.replace('www.', '')
				}
			} catch (err) {
				console.log('invalid url', err)
			}
		},
	},
	watch: {
		'jurisdiction.website'() {
			this.updateSlug()
		},
	},
}
</script>
