<template>
	<div class="page-onboarding">
		<h1 class="text-center">Hey311 setup</h1>
		<p class="lead text-center">How should we manage 311 requests for your {{ j.type }}?</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<div
							class="bg-danger-lighter p-3 rounded-1 mb-3 cursor-pointer"
							@click="states.video = !states.video"
						>
							<p class="my-0">
								▶️ Need help? Watch a video tutorial on how to set up Hey311
							</p>
							<div v-if="states.video" class="ratio ratio-16x9 mt-2">
								<iframe
									src="https://www.youtube.com/embed/1w8WxqZCIg4"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>

						<h3>Privacy</h3>

						<div class="row mb-4">
							<div class="col">
								<div
									class="border rounded p-3 cursor-pointer"
									:class="{ 'border-success': j.features_options.issues_privacy === 'public' }"
									@click="j.features_options.issues_privacy = 'public'"
								>
									<p class="lead mb-2">
										Public <span v-if="j.features_options.issues_privacy === 'public'">✅</span>
									</p>
									<p class="mb-0">Show all 311 requests in app to everyone</p>
								</div>
							</div>
							<div class="col">
								<div
									class="border rounded p-3 cursor-pointer"
									:class="{ 'border-success': j.features_options.issues_privacy === 'resolved' }"
									@click="j.features_options.issues_privacy = 'resolved'"
								>
									<p class="lead mb-2">
										Resolved <span v-if="j.features_options.issues_privacy === 'resolved'">✅</span>
									</p>
									<p class="mb-0">Show resolved requests in app</p>
								</div>
							</div>
							<div class="col">
								<div
									class="border rounded p-3 cursor-pointer"
									:class="{ 'border-success': j.features_options.issues_privacy === 'private' }"
									@click="j.features_options.issues_privacy = 'private'"
								>
									<p class="lead mb-2">
										Private <span v-if="j.features_options.issues_privacy === 'private'">✅</span>
									</p>
									<p class="mb-0">Show only own requests and stats</p>
								</div>
							</div>
						</div>

						<h3>Requests categories</h3>
						<p>We started you off with some categories, feel free to edit them and add your own!</p>

						<table class="table">
							<thead>
								<tr>
									<th>Category</th>
									<th>Group</th>
									<th>Keywords</th>
									<th>Icon</th>
									<th></th>
								</tr>
							</thead>
							<tbody v-if="services">
								<tr
									v-for="service in services.filter(s => s.status !== 'deleted')"
									:key="service.id"
									class="on-parent"
								>
									<td>{{ service.name }}</td>
									<td>{{ service.group }}</td>
									<td>{{ service.keywords }}</td>
									<td></td>
									<td>
										<button
											class="btn btn-sm btn-outline-primary float-end show-on-hover"
											data-bs-toggle="modal"
											data-bs-target="#service-edit"
											@click="serviceSelected = service"
										>
											Edit
										</button>
									</td>
								</tr>
							</tbody>
						</table>

						<p class="text-center mb-5">
							<button
								class="btn btn-success btn-big-icon"
								data-bs-toggle="modal"
								data-bs-target="#service-edit"
								@click="serviceSelected = serviceNew"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" /> Create category
							</button>
						</p>

						<div class="row align-items-center mt-4">
							<div class="col">
								<router-link
									:to="`/${j.slug}/onboarding/${token}/departments`"
									class="btn btn-sm btn-outline-primary"
									>Back</router-link
								>
							</div>
							<div class="col text-end">
								<router-link
									v-if="j.features.includes('forms')"
									:to="`/${j.slug}/onboarding/${token}/forms`"
									class="btn btn-primary"
									>Onwards to Forms →</router-link
								>
								<router-link
									v-else
									:to="`/${j.slug}/onboarding/${token}/widget`"
									class="btn btn-primary"
									>Continue →</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="service-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="serviceSelected" class="modal-content">
					<form @submit.prevent="saveService">
						<div class="modal-header">
							<h5 class="modal-title">{{ serviceSelected.id ? 'Edit category' : 'Add category' }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="service-name">Category name</label>
								<input
									type="text"
									class="form-control"
									id="service-name"
									required
									v-model="serviceSelected.name"
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="service-group">Group</label>
								<input
									type="text"
									class="form-control"
									id="service-group"
									list="service-groups"
									required
									v-model="serviceSelected.group"
								/>

								<datalist id="service-groups">
									<option
										v-for="(servicesList, group) in servicesByGroup"
										:key="group"
										:value="group"
									/>
								</datalist>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="service-keywords">Keywords</label>
								<input
									type="text"
									class="form-control"
									id="service-keywords"
									v-model="serviceSelected.keywords"
									aria-describedby="serviceKeywordsHelp"
								/>
								<small id="serviceKeywordsHelp" class="form-text text-muted"
									>Improves category search &amp; auto assign Requests into categories. Separate
									keywords by comma <code>,</code></small
								>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="service-icon">Icon</label>
								<select class="form-select" id="service-icon" required disabled> </select>
							</div>
						</div>
						<div class="modal-footer justify-content-between">
							<button v-if="serviceSelected.id" class="btn text-danger" @click.prevent="deleteService">
								Delete
							</button>
							<button class="btn btn-primary">
								{{ serviceSelected.id ? 'Save' : 'Create category' }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import heyGovApi from '@/api.js'
import { Modal } from 'bootstrap'

export default {
	name: 'OnboardingCategories',
	data() {
		return {
			states: {
				video: false,
			},
			token: this.$route.params.token,
			$modalService: null,
			serviceSelected: null,
			serviceNew: {
				name: null,
				keywords: null,
				group: null,
				//icon: null,
			},
		}
	},
	computed: {
		...mapState(['j', 'services']),
		...mapGetters(['servicesByGroup']),
	},
	created() {
		this.$store.dispatch('loadServices')
	},
	mounted() {
		this.$modalService = new Modal(document.getElementById('service-edit'))
	},
	methods: {
		updateJurisdiction() {
			heyGovApi
				.put(`/jurisdictions/${this.j.id}`, {
					features_options: this.j.features_options,
				})
				.then(() => {
					Vue.toasted.success('Jurisdiction info is updated')
				})
		},
		saveService() {
			if (this.serviceSelected.id) {
				heyGovApi
					.put(`/${this.j.slug}/services/${this.serviceSelected.id}`, {
						name: this.serviceSelected.name,
						group: this.serviceSelected.group,
						keywords: this.serviceSelected.keywords,
					})
					.then(
						() => {
							this.$modalService.hide()
							Vue.toasted.show(`Category "${this.serviceSelected.name}" is saved`)
						},
						error => {
							Vue.toasted.error(error)
						}
					)
			} else {
				heyGovApi.post(`/${this.j.slug}/services`, this.serviceSelected).then(
					({ data }) => {
						this.$store.commit('addService', data)
						Vue.toasted.show(`Category "${this.serviceSelected.name}" is added`)

						// empty data from dialog & hide it
						this.$modalService.hide()
						this.serviceSelected.name = ''
						this.serviceSelected.group = ''
						this.serviceSelected.keywords = ''
					},
					error => {
						Vue.toasted.error(error)
					}
				)
			}
		},
		deleteService() {
			if (confirm('For sure remove this category?')) {
				heyGovApi.delete(`/${this.j.slug}/services/${this.serviceSelected.id}`).then(
					() => {
						this.serviceSelected.status = 'deleted'
						this.$modalService.hide()
						Vue.toasted.show(`Category "${this.serviceSelected.name}" is deleted`)
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
			}
		},
	},
	watch: {
		'j.features_options': {
			handler() {
				this.updateJurisdiction()
			},
			deep: true,
		},
	},
}
</script>
