<template>
	<div class="page-onboarding">
		<h1 class="text-center">Add HeyGov to your website</h1>
		<p class="lead text-center">Make it easier to receive 311 requests or forms</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<p class="lead">
							Upgrade your website with a helpful widget that makes it easier to send 311 requests or
							start form applications.
						</p>

						<div class="row mb-3">
							<div class="col">
								<img
									src="https://files.heygov.com/assets/demo-widget-1.png"
									alt="heygov widget"
									class="img-fluid"
								/>
							</div>
							<div class="col">
								<img
									src="https://files.heygov.com/assets/demo-widget-2.png"
									alt="heygov widget"
									class="img-fluid"
								/>
							</div>
						</div>

						<div
							class="bg-danger-lighter p-3 rounded-1 mb-3 cursor-pointer"
							@click="states.video = !states.video"
						>
							<p class="my-0">
								▶️ Need help? Watch a video tutorial on how to use the HeyGov widget
							</p>
							<div v-if="states.video" class="ratio ratio-16x9 mt-2">
								<iframe
									src="https://www.youtube.com/embed/d_dEwjlMcXk"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>

						<p>What kind of website do you have?</p>

						<ul class="nav nav-tabs mb-4">
							<li class="nav-item cursor-pointer">
								<span
									class="nav-link"
									:class="{ active: siteType === 'wordpress' }"
									@click="siteType = 'wordpress'"
									>WordPress</span
								>
							</li>
							<li class="nav-item cursor-pointer">
								<span
									class="nav-link"
									:class="{ active: siteType === 'townweb' }"
									@click="siteType = 'townweb'"
									>Town Web client</span
								>
							</li>
							<li class="nav-item cursor-pointer">
								<span
									class="nav-link"
									:class="{ active: siteType === 'other' }"
									@click="siteType = 'other'"
									>Other website</span
								>
							</li>
							<li class="nav-item cursor-pointer">
								<span
									class="nav-link"
									:class="{ active: siteType === 'doesntknow' }"
									@click="siteType = 'doesntknow'"
									>I don't know</span
								>
							</li>
						</ul>

						<div v-if="siteType === 'wordpress'" class="bg-light rounded-2 p-3">
							<p>
								Adding HeyGov to a WordPress with one of these options:
							</p>
							<ul>
								<li>
									<a :href="wpPluginUrl" target="_blank"> Install plugin now</a>
								</li>
								<li>
									OR
									<a href="https://wordpress.org/plugins/heygov/" target="_blank"
										>Download HeyGov plugin ⬇</a
									>
									from WordPress.org
								</li>
							</ul>
						</div>
						<div v-else-if="siteType === 'other'" class="bg-light rounded-2 p-3">
							<p class="mb-1">
								Place this line of code in <code>&lt;head&gt;</code> or at end of
								<code>&lt;body&gt;</code> HTML section in your website:
							</p>
							<textarea
								class="form-control form-control-code mb-3"
								v-model="widgetEmbedCode"
								readonly
								rows="3"
							></textarea>
						</div>
						<div v-else-if="siteType === 'townweb'" class="bg-light rounded-2 p-3">
							<p class="mb-1">
								If you are a client of <a href="https://townweb.com" target="_blank">Town Web</a>, we'll
								coordinate with them to install &amp; activate the widget.
							</p>
						</div>
						<div v-else class="bg-light rounded-2 p-3">
							<p>
								If you are unsure, you can send the widget embed instructions to the developer in charge
								of your website.
							</p>
							<p>Fill their email below, and we'll send a message with detailed instructions.</p>

							<form @submit.prevent="sendDevEmail">
								<div class="input-group mb-3">
									<input
										type="email"
										class="form-control"
										v-model="devEmail"
										required
										placeholder="Email of website developer"
									/>
									<button class="btn btn-outline-secondary">
										Send email
									</button>
								</div>
							</form>
						</div>

						<div class="row align-items-center mt-4">
							<div class="col">
								<router-link
									v-if="j.features.includes('forms')"
									:to="`/${j.slug}/onboarding/${token}/forms`"
									class="btn btn-sm btn-outline-primary"
									>Back</router-link
								>
								<router-link
									v-else
									:to="`/${j.slug}/onboarding/${token}/categories`"
									class="btn btn-sm btn-outline-primary"
									>Back</router-link
								>
							</div>
							<div class="col text-end">
								<router-link :to="`/${j.slug}/onboarding/${token}/finish`" class="btn btn-primary"
									>Continue →</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import heyGovApi from '@/api.js'

export default {
	name: 'OnboardingFinish',
	data() {
		return {
			states: {
				video: false,
			},
			token: this.$route.params.token,
			siteType: 'wordpress',
			widgetType: 'widget',
			widgetButtonType: 'bottom-right',
			devEmail: '',
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j', 'forms']),
		wpPluginUrl() {
			let pluginUrl = this.j.website

			if (!pluginUrl.endsWith('/')) {
				pluginUrl += '/'
			}

			return pluginUrl + 'wp-admin/plugin-install.php?s=heygov&tab=search&type=term'
		},
		widgetEmbedCode() {
			const button =
				this.widgetType === 'widget' && this.widgetButtonType !== 'none'
					? ` data-heygov-button-style="${this.widgetButtonType}"`
					: ''
			return `<script src="https://files.heygov.com/widget.js" data-heygov-jurisdiction="${
				this.j.slug
			}"${button}><${'/'}script>`
		},
	},
	created() {
		this.$store.dispatch('getForms')
		this.$store.dispatch('loadDepartments')
	},
	methods: {
		sendDevEmail() {
			heyGovApi
				.post(`/${this.j.slug}/onboarding/send-dev-email`, {
					email: this.devEmail,
				})
				.then(() => {
					Vue.toasted.success('Email with instructions is sent!')
				})
		},
	},
}
</script>
