<template>
	<div class="page-onboarding">
		<h1 class="text-center">Departments &amp; Members</h1>
		<p class="lead text-center">Who works where?</p>

		<div class="row justify-content-center">
			<div class="col-sm-10 col-lg-8">
				<div class="card mb-5">
					<div class="card-body">
						<p>
							Departments in HeyGov are the equivalent of the departments in your municipal government
							(e.g., Fire Department, Police Department, Public Works, etc.)
						</p>
						<p>
							As a start, we created Administration and added you as HeyGov Admin. Now you can add the
							other departments &amp; department members in your municipality!
						</p>

						<div
							class="bg-danger-lighter p-3 rounded-1 mb-3 cursor-pointer"
							@click="states.video = !states.video"
						>
							<p class="my-0">
								▶️ Need help? Watch a video tutorial on how to add or modify Departments
							</p>
							<div v-if="states.video" class="ratio ratio-16x9 mt-2">
								<iframe
									src="https://www.youtube.com/embed/U1xQDsVozac"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>

						<div v-if="departments">
							<div
								v-for="department in departments"
								:key="`onboarding-department-${department.id}`"
								class="border rounded mb-3 p-3"
							>
								<div class="row align-items-center mb-3">
									<div class="col-auto">
										<img
											:src="
												department.logo ||
													`https://eu.ui-avatars.com/api/?size=100&background=${department.color.slice(
														1
													)}&name=${department.name}`
											"
											class="rounded shadow"
											width="65"
											height="65"
											:alt="department.name"
										/>
									</div>
									<div class="col">
										<h3 class="m-0">{{ department.name }}</h3>
										<p class="m-0">{{ department.email }}</p>
										<p class="m-0">{{ department.phone }}</p>
									</div>
									<div class="col">
										<a v-if="department.location" target="_blank" :href="department.location.url">
											📍{{ truncateString(department.location.name, 25) }}
										</a>
									</div>

									<div class="col-auto">
										<button
											class="btn btn-primary btn-sm btn-big-icon"
											data-bs-toggle="modal"
											data-bs-target="#person-add"
											@click="
												personSelected = personNew
												departmentNew = department
											"
										>
											<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add member
										</button>
									</div>
								</div>

								<table v-if="department.people.length" class="table mb-0">
									<thead>
										<tr>
											<th>Name</th>
											<th>Role</th>
											<th>Email</th>
											<th>Phone</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="deptMember in department.people"
											:key="`dept-${department.id}-person-${deptMember.person_id}`"
										>
											<td>
												<span v-if="people[deptMember.person_id]">{{
													people[deptMember.person_id].name
												}}</span>
											</td>
											<td>
												<span class="badge" :class="roles[deptMember.role].class">{{
													roles[deptMember.role].name
												}}</span>
											</td>
											<td>
												<span v-if="people[deptMember.person_id]">{{
													people[deptMember.person_id].email
												}}</span>
											</td>
											<td>
												<span v-if="people[deptMember.person_id]">{{
													people[deptMember.person_id].phone
												}}</span>
											</td>
										</tr>
									</tbody>
								</table>
								<p v-else class="text-center mb-0 text-muted">No members in this department yet..</p>
							</div>
						</div>

						<p class="text-center mb-5">
							<button
								class="btn btn-outline-success btn-big-icon"
								data-bs-toggle="modal"
								data-bs-target="#department-add"
								@click="departmentNew = departmentNew"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" /> Create department
							</button>
						</p>

						<div class="row align-items-center mt-4">
							<div v-if="j.stripe_subscription_id" class="col">
								<router-link
									:to="`/${j.slug}/onboarding/${token}/billing`"
									class="btn btn-sm btn-outline-primary"
									>Back to Billing</router-link
								>
							</div>
							<div class="col text-end">
								<router-link
									v-if="j.features.includes('issues')"
									:to="`/${j.slug}/onboarding/${token}/categories`"
									class="btn btn-primary"
									>Onwards to Hey311 →</router-link
								>
								<router-link v-else :to="`/${j.slug}/onboarding/${token}/forms`" class="btn btn-primary"
									>Onwards to Forms →</router-link
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="department-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="departmentNew" class="modal-content">
					<form @submit.prevent="saveDepartment">
						<div class="modal-header">
							<h5 class="modal-title">Create department</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="department-name">Department name</label>
								<input
									type="text"
									class="form-control"
									id="department-onboarding-name"
									required
									v-model="departmentNew.name"
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="department-email">Email address</label>
								<input
									type="email"
									class="form-control"
									id="department-onboarding-email"
									required
									v-model="departmentNew.email"
									aria-describedby="departmentEmailHelp"
								/>
								<small id="departmentEmailHelp" class="form-text text-muted"
									>Department's group email, for general notifications.</small
								>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="department-onboarding-phone">Department phone</label>
								<vue-phone-number-input
									id="department-phone"
									v-model="departmentNew.phone"
									:default-country-code="defaultCountry"
									:preferred-countries="preferedCountries"
									class="mb-2"
									@update="setPhoneNumber"
									:border-radius="20"
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="department-onboarding-location"
									>Department address</label
								>
								<gmap-autocomplete
									:options="autocompleteOptions"
									:select-first-on-enter="true"
									@place_changed="setDepartmentLocation"
								>
									<template v-slot:default="slotProps">
										<input
											id="department-onboarding-location"
											class="form-control"
											ref="input"
											:value="departmentNew.location ? departmentNew.location.address : ''"
											placeholder="Department address"
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<p class="text-end mb-0">
								<button class="btn btn-primary">Create department</button>
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="person-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="personSelected" class="modal-content">
					<form @submit.prevent="savePerson">
						<div class="modal-header">
							<h5 class="modal-title">
								Add member to <strong>{{ departmentNew.name }}</strong>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="person-name">Name</label>
								<input
									type="text"
									class="form-control"
									id="person-name"
									required
									v-model="personSelected.name"
									aria-describedby="personNameHelp"
								/>
								<small id="personNameHelp" class="form-text text-muted"
									>Use person's real name, will be helpful in HeyLicense and Hey311.</small
								>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="person-email">Email address</label>
								<input
									type="email"
									class="form-control"
									id="person-email"
									required
									:readonly="personSelected.id"
									v-model="personSelected.email"
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="person-phone">Mobile phone</label>
								<vue-phone-number-input
									id="department-phone"
									v-model="personSelected.phone"
									:default-country-code="defaultCountry"
									:preferred-countries="preferedCountries"
									class="mb-2"
									@update="setPhoneNumber"
									:border-radius="20"
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="person-role">Role</label>
								<select class="form-select" id="person-role" required v-model="personSelected.role">
									<option v-for="(role, roleId) in roles" :key="roleId" :value="roleId">{{
										role.name
									}}</option>
								</select>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="person-title">Title</label>
								<input
									type="text"
									class="form-control"
									id="person-title"
									v-model="personSelected.title"
									placeholder="PD agent, treasurer, etc"
								/>
							</div>

							<div class="alert alert-info mb-3">
								An email message will be sent to the person, so they can create a password for their
								account.
							</div>

							<p class="text-end mb-0">
								<button class="btn btn-primary">Add person</button>
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import heyGovApi from '@/api.js'
import { Modal } from 'bootstrap'
import parsePhoneNumber from 'libphonenumber-js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { truncateString } from '@/utils.js'

export default {
	name: 'OnboardingDepartments',
	components: { VuePhoneNumberInput },
	data() {
		return {
			states: {
				video: false,
			},
			token: this.$route.params.token,
			section: 'departments',
			$modalDepartment: null,
			$modalPerson: null,
			personSelected: null,
			personNew: {
				name: null,
				email: null,
				phone: null,
				department_id: null,
				role: null,
			},
			departmentNew: {
				name: '',
				email: '',
				phone: '',
				location: {},
			},
			defaultCountry: 'US',
			preferedCountries: ['US', 'CA'],
			pNumber: '',

			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['formatted_address', 'geometry', 'name', 'place_id', 'url', 'icon', 'icon_mask_base_uri'],
			},
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j', 'departments', 'people', 'roles']),
		/* 		shortName() {
			return toLowerCase(this.department.name)
		}, */
	},
	created() {
		this.$store.dispatch('loadDepartments').then(departments => {
			// load department member details
			if (departments && departments.length) {
				this.$store.dispatch('loadPeople', departments.map(d => d.people.map(p => p.person_id)).flat())
			}
		})

		this.preparePhoneNumber()
	},
	mounted() {
		this.$modalDepartment = new Modal(document.getElementById('department-add'))
		this.$modalPerson = new Modal(document.getElementById('person-add'))
	},
	methods: {
		truncateString,

		saveDepartment() {
			heyGovApi.post(`/${this.j.slug}/departments`, this.departmentNew).then(
				({ data }) => {
					this.$store.commit('addDepartment', data)
					if (data.location) {
						data.location = JSON.parse(data.location)
					}
					this.$modalDepartment.hide()
					Vue.toasted.show(`Department "${this.departmentNew.name}" is created`)

					// clear new department data
					this.departmentNew = {}
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		savePerson() {
			this.personSelected.department_id = this.departmentNew.id
			heyGovApi.post(`/${this.j.slug}/departments/people`, this.personSelected).then(
				({ data }) => {
					this.departmentNew.people.push(data)
					this.$modalPerson.hide()
					Vue.toasted.show(`"${this.personSelected.name}" is invited`)

					// clear new person data
					this.personNew.name = null
					this.personNew.email = null
					this.personNew.phone = null
					this.personNew.role = null
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		setPhoneNumber(payload) {
			if (payload.isValid) {
				const phone = '+' + payload.countryCallingCode + ' ' + payload.phoneNumber
				if (this.departmentNew.phone !== phone) {
					this.departmentNew.phone = phone
				}
			}
		},
		preparePhoneNumber() {
			if (this.departmentNew && this.departmentNew.phone) {
				const parsed = parsePhoneNumber(this.departmentNew.phone)

				if (parsed) {
					this.defaultCountry = parsed.country
					this.pNumber = parsed.nationalNumber
				} else {
					this.pNumber = this.departmentNew.phone
				}
			}
		},
		setDepartmentLocation(place) {
			if (!this.departmentNew.location || typeof this.departmentNew.location !== 'object') {
				this.departmentNew.location = {}
			}

			this.departmentNew.location.name = place.name
			this.departmentNew.location.address = place.formatted_address
			this.departmentNew.location.place_id = place.place_id
			this.departmentNew.location.lat = place.geometry.location.lat()
			this.departmentNew.location.lng = place.geometry.location.lng()
			this.departmentNew.location.url = place.url
			this.departmentNew.location.icon = place.icon
			this.departmentNew.location.icon_svg = `${place.icon_mask_base_uri}.svg`
		},
	},
}
</script>
